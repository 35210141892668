import { App } from "vue";

import PrsCore_User from "@/core/appcore/prscores/user/PrsCore_User";
import PrsCore_Flight from "@/core/appcore/prscores/flight/PrsCore_Flight";
import PrsCore_Reservation from "@/core/appcore/prscores/reservation/PrsCore_Reservation";
import PrsCore_Invoice from "@/core/appcore/prscores/invoice/PrsCore_Invoice";

class AppCore {

    public static ccvVer = "2024-08-21 1.1.1.149";

    public static vueInstance: App;

    public static prsCore_User: PrsCore_User;
    public static prsCore_Flight: PrsCore_Flight;
    public static prsCore_Reservation: PrsCore_Reservation;
    public static prsCore_Invoice: PrsCore_Invoice;

    public static init(app: App<Element>) {
        AppCore.vueInstance = app;

        AppCore.prsCore_User = new PrsCore_User();
        AppCore.prsCore_Flight = new PrsCore_Flight();
        AppCore.prsCore_Reservation = new PrsCore_Reservation();
        AppCore.prsCore_Invoice = new PrsCore_Invoice();

        console.log("=== AppCore ccvVer: "+this.ccvVer);

        AppCore.prsCore_User.initComp();
        AppCore.prsCore_Flight.initComp();
        AppCore.prsCore_Reservation.initComp();
        AppCore.prsCore_Invoice.initComp();

    }

}

export default AppCore;